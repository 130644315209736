import { rem } from 'polished';
import styled from 'styled-components';
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../constants';
import { breakpoints } from '../../styles/theme';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${rem(MOBILE_HEADER_HEIGHT)};

    @media (min-width: ${breakpoints.sm}px) {
        margin-top: ${rem(DESKTOP_HEADER_HEIGHT)};
    }
`;

export const Title = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.h1};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    color: ${({ theme }) => theme.colors.primary};
`;

export const SubTitle = styled.span`
    padding: ${rem(10)} 0 ${rem(15)} 0;
    font-size: ${({ theme }) => theme.fontSizes.h6};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
`;

export const Description = styled.p`
    text-align: center;
    line-height: ${rem(24)};
`;
