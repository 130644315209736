import React from 'react';
import { NextPage } from 'next';
import FooterTemplate from '@components/Footer/Footer';
import HeaderTemplate from '@components/Header/Header';
import NoteFoundErrorTemplate from '@components/NotFoundError/NotFoundError';
import useTranslation from '@hooks/useTranslation';
import ContentTemplate from '@ui/Content/Content';

const NotFound: NextPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <HeaderTemplate />
            <ContentTemplate withPadding>
                <NoteFoundErrorTemplate />
            </ContentTemplate>
            <FooterTemplate />
        </>
    );
};

export default NotFound;
