import React from 'react';
import { FC } from 'react';
import { useRouter } from 'next/router';
import useTranslations from '@hooks/useTranslation';
import Button from '@ui/Button/Button';
import { ROUTES } from '../../constants';
import { Description, SubTitle, Title, Wrapper } from './NotFoundError.elements';

export interface NoteProps {}

const NoteFoundErrorTemplate: FC<NoteProps> = () => {
    const { t } = useTranslations();

    const router = useRouter();

    return (
        <Wrapper>
            <Title>{t('error.404.title')}</Title>
            <SubTitle>{t('error.404.subTitle')}</SubTitle>
            <Description dangerouslySetInnerHTML={{ __html: t('error.404.description') }} />
            <Button
                onClick={() => router.replace({ pathname: ROUTES.HOME })}
                marginTop={40}
                marginBottom={40}
                text={t('error.404.button')}
            />
        </Wrapper>
    );
};

export default NoteFoundErrorTemplate;
